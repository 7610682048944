
import { Options, Vue } from 'vue-class-component';
import { message } from 'ant-design-vue/es';
import * as _ from 'lodash';
import { throttle } from '../../untils/until';
import { defineComponent, reactive, ref, UnwrapRef } from 'vue';
import { Emit, Provide, Watch } from 'vue-property-decorator';
import { platformManagement, commonController, fwfjbxxSave, ownerListId} from '../../api/platform';
import { Form } from 'ant-design-vue';
import moment, { Moment} from 'moment'
const useForm = Form.useForm;
interface FormState {
  nsrmc: string;
  nsrsbh: string;
  hy: string;
  zgswskfj: string;
  nsrzt: string;
  zcdz: string;
  zcdzxzqh: string;
  zgswjg: string;
  fddbrxm: string;
  scjydzxzqh: string;
  jdxz: string;
  scjydz: string;
}
interface ownerList {
  pageNum: number;
  pageSize: number;
  name: string;
  type: number;
}

interface DataItem {
  key: string;
  name: string;
  age: number;
  address: string;
}
/**
 * Greeter类，公告查询
 * @constructor
 * @return {*}
 */
class Greeter {
    val: string;
    index: number;
    constructor(message: string,type: number) {
      this.val = message;
      this.index = type
    }
    greet() {
       const data:any = {
         pageNum: 1,
         pageSize: 100,
         name: this.val,
         type: this.index
       }
      return commonController(data)
    }
}
@Options({
  components: {
  },
})
export default class OwnerDatails extends Vue {
 @Provide() columns: any =[
  {
    title: '开户人姓名',
    dataIndex: 'khrxm',
  },
  {
    title: '开户人身份证号',
    dataIndex: 'khrsfzjhm',
    slots: { customRender: 'khrsfzjhm' },
  },
  {
    title: '开户银行',
    dataIndex: 'khyh',
    slots: { customRender: 'khyh' },
  },
  {
    title: '银行卡号',
    dataIndex: 'skzh',
  },
];
   rules: any = {
      nsrmc: [
        { required: true, message: '请输入纳税人名称', trigger: 'blur' },
      ],
      fwfxm: [
        { required: true, message: '请输入车主姓名', trigger: 'blur' },
      ],
      fwfsfzjhm: [
         { required: true, message: '请输入身份证号码', trigger: 'blur' },
         { pattern: /^[0-9]{17}[0-9|x|X]{1}$/, message: '请输入合法的身份证号',trigger: 'blur' },
      ],
      jszfjPic: [{required: true, message: '请上传司机驾驶证照片'}],
      yddh: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
         { pattern: /^1[3|4|5|7|8]\d{9}$/, message: '请输入合法的手机号码',trigger: 'blur' },
      ],
      jszjhm: [
         { required: true, message: '请输入驾驶证件号码', trigger: 'blur' },
      ],
      ptzcsj: [
         { required: true, message: '请输入服务方注册时间', trigger: 'blur' },
      ],
      cycph: [
         { required: true, message: '请输入承运车牌号', trigger: 'blur' },
      ],
      xszjhm: [
         { required: true, message: '请输入行驶证件号码', trigger: 'blur' },
      ],
      cycc: [
        { required: true, message: '请输入承运车长', trigger: 'blur' },
      ],
      cyzz: [
        { required: true, message: '请输入承运载重（吨）', trigger: 'blur' },
      ],
      cypp: [
         { required: true, message: '请输入承运车辆品牌', trigger: 'blur' },
      ],
      clsyr:[
        { required: true, message: '请输入车辆所有人', trigger: 'blur' },
      ],
      cycsjsyr:[
         { required: true, message: '请输入实际所有人', trigger: 'blur' },
      ],
      cycsyrlxfs:[
         { required: true, message: '请输入所有人联系方式', trigger: 'blur' },
      ],
      cyczzl:[
        { required: true, message: '请输入总质量（吨）', trigger: 'blur' },
      ],
      cyczbzl:[
        { required: true, message: '请输入整备质量（吨）', trigger: 'blur' },
      ],
      ssdq: [
         { required: true, message: '请输入服务方所属地区', trigger: 'blur' },
      ],
      hy: [
        { required: true, message: '请选择行业', trigger: 'blur' },
      ],
      nsrzt: [
         { required: true, message: '请输入纳税人状态', trigger: 'blur' },
      ],
      ccnf: [
         { required: true, message: '请输入出厂年份', trigger: 'blur' },
      ],
      cyczcsj:[{
        required: true, message: '请输入出厂年份', trigger: 'blur'
      }],
      cycfdjhm:[{
        required: true, message: '请输入发动机号码', trigger: 'blur'
      }],
      jyrq:[{
        required: true, message: '请输入检查日期', trigger: 'blur'
      }],
      cycsbh:[{
        required: true, message: '请输入车辆识别代码', trigger: 'blur'
      }],
      cycsyxz:[{
        required: true, message: '请输入使用性质', trigger: 'blur'
      }],
      fwfsfzjlx:[{
         required: true, message: '请输入使用性质', trigger: 'blur'
      }],
      cycx:[{
         required: true, message: '请输入承运车型',trigger: 'blur'
      }],
   }
  disabledDate: any = (current: Moment)=> {
    return current && current > moment().endOf('day');
  }
  formState:any = {
    fwfuuid:"",
    vehicle:1,
    addForm:[],
    jszfjPic: "",
    smrzfjPic: ["",""],
    clxxModels:[
      { cycc:"",cycx:"",
        xszjhm:"",cycph:"",cyzz:"",
        cypp:"",cycsyrlxfs:"",
        cycsjsyr:"",clsyr:"",
        cyczzl:"",cyczcsj:"",cycfdjhm:"",
        jyrq:"",cycsbh:"",cycsyxz:"",
        cycxszfjPic:"",
        cyczpfjPic:"",
        urlPic:[],
        jszlqrq:"",
        zjcx:"",
        jszfjThn:"",
        jszfjPic:"",

      }
    ]
  }
  //验证数据
  verifyStatedOptions:any = [];
  //证件类型
  personCertificate:any = [];

  RemoveLImg(file: File): void {
     this.formState.jszfjPic = "";
     this.formRef = this.$refs.formRef;
     this.formRef.validateFields('jszfjPic');
  }

  /**
   * @description: 取消
   * @param {*}
   * @return {*}
   */  
  onCancel():void {
      this.$router.push('/owner')
  }
  

  imgUrltitle:string ="";
  visible:boolean = false;
  data:any = [];
  //主管税务所
  taxOffice:any = [];
  // 选中值
  vehicle:number = 1;
  // 选中
  editableData:any =[]
  // 图片
  frontPhoto:any = [];
  frontPhoto1:any = [];
  columnsValue:any ={};
  formRef: any = {}
  // 性质
  verifyStated:any = [
      {name:"营运",id:0},
      {name:"非营运",id:1},
  ];
  // 车型
  quasiDriving:any = [
     {name:"A1"},
     {name:"A2"},
     {name:"A3"},
     {name:"B1"},
     {name:"B2"},
     {name:"C1"},
     {name:"C2"},
     {name:"C3"},
     {name:"C4"},
     {name:"D"},
     {name:"E"},
     {name:"F"},
     {name:"M"},
     {name:"N"},
     {name:"P"},
  ]
  locale:any ={          
    "lang": {            
        "placeholder": "请选择日期",            
        "today": "今天",            
        "yearFormat": "YYYY",            
        "dateFormat": "M/D/YYYY",            
        "dayFormat": "D",            
        "dateTimeFormat": "M/D/YYYY HH:mm:ss",            
        "monthFormat": "MMMM"       
    }
  }

   addRoules(value:any):void {
  
   }
   backList(){
     sessionStorage.setItem('isOwnerDetail', 'true')
     this.$router.back()
   }
   disabledRangeTime(){
     const date = new Date()
     return {
       disabledHours:new Date().getHours()
      }
   }
   /**
   * handleChange1类，行业查询
   * shipper接口参数限制
   * @constructor
   * @return {*}
   */
   handleChange1(value: string):void {
     this.formState.ssdqCode = value
   }
  url: string = process.env.VUE_APP_BASEURL;
  location:any = localStorage.tsToken;
  previewVisible:boolean = false;
  smrzfjPicBoole:boolean = false;
  smrzfjPicBoole1:boolean = false;
  fileList:any = [];
  urlPic:any = [];
  eachDistrict:any = [];
  @Emit()
/**
 * handleSearch类，行业查询
 * shipper接口参数限制
 * @constructor
 * @return {*}
 */
  handleSearch(val:any): void{
    const data:ownerList={
       pageNum: 1,
       pageSize: 100,
       name: val,
       type: 4
    }
    commonController(data).then((res: any) => {
       this.verifyStatedOptions = res.data.data.list
    })
  }

  handleSearchEachDistrict(val:any): void{
    let handCommon= new Greeter(val,2);
    handCommon.greet().then((res:any)=>{
      this.eachDistrict = res.data.list
    })
  }

  handChange(itmme:any,name:string):void{
    var dome:any 
    switch (name) {
        case 'cycph':
        dome = this.$refs.cycph
        break;
        case 'xszjhm':
        dome = this.$refs.xszjhm
        break;
        case 'cycx':
        dome = this.$refs.cycx
        break;
        case 'cycc':
        dome = this.$refs.cycc
        break;
        case 'cyzz':
        dome = this.$refs.cyzz
        break;
        case 'cypp':
        dome = this.$refs.cypp
        break;
        case 'clsyr':
        dome = this.$refs.clsyr
        break;
        case 'cycsjsyr':
        dome = this.$refs.cycsjsyr
        break;
        case 'cycsyrlxfs':
        dome = this.$refs.cycsyrlxfs
        break;
    }
    if(itmme == '') {
       dome.onFieldBlur()
    } else {
       dome.clearValidate(name)
    }
  }
  // 时间
  onChange(value: Moment[], dateString: string):void {
     this.formState.jszlqrq = dateString
  }
   onChange1(value: Moment[], dateString: string):void {
     this.formState.ptzcsj = dateString
  }

 /**
 * Greeter类，查询
 * handleSearchCertificate方法，主管税务局接口查询
 * @constructor
 * @return {*}
 */
  handleSearchCertificate(val:any): void{
    let handCommon= new Greeter(val,4);
    handCommon.greet().then((res:any)=>{
      this.taxOffice = res.data.data.list
    })
  }

  handeUpde =(info:any,index:number) => {
    if (info.file.status === 'uploading') {
         return;
      }
      if (info.file.status === 'done') {
         this.formState.yyzzfjPic = info.file.response.data.url;
      }
      if (info.file.status === 'error') {
        message.error('上传失败');
      }
  }

   // 上传图片
  handlePreview():void {
    this.previewVisible = true
  }

  // 取消
  handleCancel():void {
    this.previewVisible = false
  }

  // 查看图片
  enterprisePhone(data:any,isAdjunc:any){
    console.log(isAdjunc)
    if(isAdjunc == 1) {
      this.visible = true;
      this.imgUrltitle = data
    }else {
      message.error("该车主不是挂靠车辆")
    }
  
  }
  
  RemovefrontPhoto():void{
    this.formState.smrzfjPic[0]=""
  }
  RemovefrontPhoto1():void{
    this.formState.smrzfjPic[1]=""
  }

  setTimeoutSave():void{
    if(this.formState.smrzfjPic[0]==""){
       message.error("请上传身份证正面")
       return
    }
    if(this.formState.smrzfjPic[1]==""){
       message.error("请上传身份证反面")
       return
    }
  try {
    this.formState.clxxModels.forEach((data:any,i:any) => {
      if(data.cycxszfjPic==''){
         message.error("请上传承运车辆行驶证照片")
         throw new Error("LoopTerminates");
      }
      if(data.cyczpfjPic==''){
         message.error("请上传承运车辆照片")
         throw new Error("LoopTerminates");
      }
      if(data.wszmImg==''){
         message.error("请上传完税证明照片")
         throw new Error("LoopTerminates");
      }
       if(data.yhlsImg==''){
         message.error("请上传银行流水证明照片")
         throw new Error("LoopTerminates");
      }
       // 租赁合同
      if(data.zlhtImg==''){
         message.error("请上传租赁合同照片")
         throw new Error("LoopTerminates");
      }
      if(data.cycph==""){
         message.error("请输入承运车牌号")
         throw new Error("LoopTerminates");
      }
      if(data.xszjhm==""){
          message.error("请输入行驶证件号码")
          throw new Error("LoopTerminates");
      }
      if(data.cycx==""){
          message.error("请输入承运车型")
          throw new Error("LoopTerminates");
      }
      if(data.cycc==""){
          message.error("请输入承运车长")
          throw new Error("LoopTerminates");
      }
      if(data.cyzz==""){
          message.error("请输入承运载重")
          throw new Error("LoopTerminates");
      } else {
        const regt:any = /^(\-|\+)?\d+(\.\d+)?$/;
        console.log(regt.test(data.cyzz))
        if(!regt.test(data.cyzz)) {
           message.error("承运载重只能为数字")
           throw new Error("LoopTerminates");
        }
      }
      // cypp
      if(data.cypp==""){
          message.error("请输入承运车辆品牌")
         throw new Error("LoopTerminates");
      }
       if(data.clsyr==""){
          message.error("请输入车辆所有人")
          throw new Error("LoopTerminates");
      }
      // cycsjsyr
       if(data.cycsjsyr==""){
          message.error("请输入实际所有人")
          return
      }
      // 所有人联系方式
      if(data.cycsyrlxfs==""){
          message.error("请输入所有人联系方式")
          throw new Error("LoopTerminates");
      } else {
        const reg:any = new RegExp(/^1[3|4|5|7|8]\d{9}$/)
        if(!reg.test(data.cycsyrlxfs)) {
           message.error("联系方式不正确")
           throw new Error("LoopTerminates");
        }
      }
      fwfjbxxSave(this.formState).then((res:any)=>{
        if(res.code==200) {
          message.success(res.message);
          this.$router.push('/owner')
        }
      })    
    });
  } catch (e) {
    if (e.message !== "LoopTerminates") throw e;
  }
}
/**
 * save方法，平台信息保存
 * @constructor
 * @return {*}
 */
//  
  save():void {
    throttle(this.setTimeoutSave,1000)()
  }
  dataSource:any
  // 
  overList():void {
   if(this.$router.currentRoute.value.query.query == 'undefind'){
     return
   }
    ownerListId(this.$router.currentRoute.value.query.query).then((res: any) =>{
      this.formState = res.data;
      // if(this.formState.smrzfjPic==null) {
      //   this.formState.smrzfjPic =["",""]
      // }
      this.urlPic = [
        {
          uid: "-1",
          name: 'image.png',
          status: 'done',
          url:this.formState.jszfjThn 
        }
      ]
      this.frontPhoto =  [
        {
          uid: "-1",
          name: 'image.png',
          status: 'done',
          url:this.formState.smrzfjPic==null?"":this.formState.smrzfjPic[0]
        }
      ]
      this.frontPhoto1 =  [
        {
          uid: "-1",
          name: 'image.png',
          status: 'done',
          url:this.formState.smrzfjPic==null?"":this.formState.smrzfjPic[1]
        }
      ]
      this.formState.clxxModels.forEach((data:any,key:any) => {
        data.url=[{
          uid: key,
          name: 'image.png',
          status: 'done',
          url:data.cyczpfjThn
         }];
         data.urlBoolen = false;
         // 车辆数据
         data.urlPic = [
            {
              uid: "-1",
              name: 'image.png',
              status: 'done',
              url:data.jszfjThn 
            }
          ]
         data.urlPicBoolen = false;
         // 图片
         data.urlCarrier=[{
            uid: key,
            name: 'image.png',
            status: 'done',
            url:data.cycxszfjThn
          }]
          data.urlCarrierBoolen = false;
       
        // 租凭合同
        data.urlRental =[{
          uid: key,
          name: 'image.png',
          status: 'done',
          url:data.zlhtImg
        }]
        data.urlRentalBoolen = false;
        // 银行流水
        data.urlBank =[{
          uid: key,
          name: 'image.png',
          status: 'done',
          url:data.yhlsImg
        }]
         data.urlBankBoolen = false;
        // 完税证明
        data.urlPayment =[{
          uid: "-2",
          name: 'image.png',
          status: 'done',
          url:data.wszmImg
        }]
        data.uurlPaymentBoolen = false;
      });
      })

  }

    edit = (key: string) => {
      const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});
      editableData[key] = _.cloneDeep(this.dataSource.filter((item:any) => key === item.key)[0]);
    };
     save1 = (key: string) => {
      const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});
      Object.assign(this.dataSource.value.filter((item:any) => key === item.key)[0], editableData[key]);
      delete editableData[key];
    };
     cancel = (key: string) => {
      const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});
      delete editableData[key];
    };
  mounted() {
    platformManagement().then((res: any) =>{
  
    })
    const data:ownerList={
       pageNum: 1,
       pageSize: 100,
       name: "",
       type: 4
    }
    commonController(data).then((res: any) => {
      this.verifyStatedOptions = res.data.list
    })

    //居民身份证类型
    let handCommon= new Greeter("",4);
    handCommon.greet().then((res:any)=>{
      this.personCertificate = res.data.list
    })
    this.overList()
  }
}
